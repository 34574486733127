
import SelectLanguage from '@/components/mobile/Common/SelectLanguage.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'IntroFooter',
  components: {
    SelectLanguage,
  },
  computed: {
    ...mapGetters({
      locale: 'gds/getLocale',
    }),
    isKo() {
      return this.$i18n.locale === 'ko';
    },
  },
};
