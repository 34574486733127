
import { ZoneOne, ZoneTwo, ZoneFour, ZoneFive, ZoneSix, ZoneSeven, IntroFooter } from '@/components/mobile/Intro';

import IntroAnimation from '@/utils/introAnimation';

export default {
  name: 'IntroPage',

  components: {
    ZoneOne,
    ZoneTwo,
    ZoneFour,
    ZoneFive,
    ZoneSix,
    ZoneSeven,
    IntroFooter,
  },

  layout: 'intro',

  data() {
    return {
      isPortrait: true,
      isShowGnbDownloadBtn: false,
    };
  },
  computed: {
    rollingImg() {
      const locale = this.$i18n.locale;
      return `/images/locales/${locale}/intro/zone03.png`;
    },
  },

  // /**
  //  * pc에서 복사한 프로필 공유 클릭시 해당 모바일 웹으로 리다이렉트
  //  */
  // middleware({ route, redirect }) {
  //   if (route?.query?.profile) {
  //     return redirect(`/user/view?profile=${route.query.profile}`);
  //   }
  // },
  watch: {
    '$i18n.locale'() {
      this.$options.$introAnimation.handleResizeAnimation();
    },
  },

  created() {
    this.$options.$observerElements = [];
    this.$options.$zoneFiveObjectElements = [];
  },

  mounted() {
    const { header, headerWrapper, zoneTwoWrapper, zoneFourWrapper, zoneFiveWrapper, footer } = this.$refs;

    const { zoneOneWrapper, videoBubbles, faceIcons, logoCharactersWrapper } = this.$refs.zoneOneWrapper.$refs;

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(
        (entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('active');
            observer.unobserve(entry.target);
          }
        },
        {
          root: null,
          threshold: 0.5,
        }
      );
    });
    this.isShowGnbDownloadBtn = !this.$isDesktop;

    this.$options.$observerElements.forEach((target) => observer.observe(target));

    this.$options.$introAnimation = new IntroAnimation({
      header,
      headerWrapper,
      footer,
      wrappers: {
        zoneOne: zoneOneWrapper,
        zoneTwo: zoneTwoWrapper,
        zoneFour: zoneFourWrapper.$el,
        zoneFive: zoneFiveWrapper.$el,
      },
      zoneFiveObjects: this.$options.$zoneFiveObjectElements,
      videoBubbles,
      faceIcons,
      logoCharacters: logoCharactersWrapper.children,
    });

    window.addEventListener('resize', this.handleWindowResize);
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
  },

  methods: {
    handleWindowResize() {
      const isPortrait = window.matchMedia('(orientation: portrait)').matches;

      if (this.isPortrait !== isPortrait) {
        this.$options.$introAnimation.handleResizeAnimation();
        this.isPortrait = isPortrait;
      }
    },

    moveToNextZone() {
      const viewportHeight = innerHeight;
      document.documentElement.scrollTo({
        top: viewportHeight < 648 ? 648 : viewportHeight,
        behavior: 'smooth',
      });
    },

    addObserverElement(el) {
      if (el) {
        if (el.classList.contains('zone02')) {
          this.$refs.zoneTwoWrapper = el;
        }
        this.$options.$observerElements.push(el);
      }
    },

    addZoneFiveObjectElement(el) {
      if (el) {
        this.$options.$zoneFiveObjectElements.push(el);
      }
    },

    async moveToAppStore(e, buildType = null) {
      try {
        await this.$moveToAppStore(buildType);
      } catch (error) {
        alert(error.message);
      }
    },
  },
};
