
export default {
  name: 'ZoneFive',

  emits: ['add-observer-el', 'add-zone-five-obj-el'],
  computed: {
    isEn() {
      return this.$i18n.locale === 'en';
    },
  },
};
