
import cookies from '@/utils/cookies';
export default {
  name: 'SelectLanguage',
  data: () => {
    return {
      languageText: {
        ko: '한국어',
        en: 'English',
        ja: '日本語',
        'zh-tw': '中文 (繁體)',
        'zh-cn': '中文 (简体)',
      },
      languageList: ['ko', 'en', 'ja', 'zh-tw', 'zh-cn'],
      isOpenLanguage: false,
    };
  },
  computed: {},
  methods: {
    getLanguageText(code) {
      return this.languageText[code];
    },
    toggleLanguage() {
      this.isOpenLanguage = !this.isOpenLanguage;
    },
    changeLocale(code) {
      if (this.$i18n.locale === code) {
        return;
      }
      // PPool 언어 UI 변경
      this.$router.replace(this.switchLocalePath(code));
      // Stove 언어팩 변경
      cookies.set('LOCALE', code.toUpperCase(), { domain: 'ppool.us', path: '/' });
    },
  },
};
