import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

const GNB_HEIGHT = 72;

// {target: HtmlElement, gsapObj: gsap}[]
// {fn: "string", args: any[]}[][]

export default class IntroAnimation {
  constructor({ header, headerWrapper, wrappers, zoneFiveObjects, footer, videoBubbles, faceIcons, logoCharacters }) {
    gsap.registerPlugin(ScrollTrigger);

    this.$header = header;
    this.$headerWrapper = headerWrapper;
    this.$footer = footer;
    this.$zoneFiveObjects = zoneFiveObjects;
    this.$videoBubbles = videoBubbles;
    this.$faceIcons = faceIcons;
    this.$logoCharacters = logoCharacters;

    this.$zoneOneWrapper = wrappers.zoneOne;
    this.$zoneTwoWrapper = wrappers.zoneTwo;
    this.$zoneFourWrapper = wrappers.zoneFour;
    this.$zoneFiveWrapper = wrappers.zoneFive;

    this.videoBubbleGsaps = null;
    this.logoGsap = null;
    this.logoCharacterGsaps = null;
    this.faceIconGsaps = null;

    this.headerScrollTrigger = null;
    this.zoneTwoScrollTrigger = null;

    this.zoneFiveBackgroundGsaps = null;

    this.#loadHeaderSvgIcon();

    this.#zoneOneAnimation();

    this.#zoneTwoAnimation();

    this.#zoneFourAnimation();

    this.#zoneFiveAnimation();
  }

  handleResizeAnimation() {
    // console.error(this.videoBubbleGsaps);
    if (!this.timeArray) {
      this.timeArray = [];
    }

    for (const arr of this.timeArray) {
      clearTimeout(arr);
    }

    this.timeArray = [];
    // setTimeout(() => {
    //   document.documentElement.scrollTo({ top: 0, left: 0 });
    //   setTimeout(() => {
    //     document.documentElement.scrollTo({ top: lastTopPosition, left: 0 });
    //     setTimeout(() => {
    //       ScrollTrigger.refresh(true);
    //     }, 20);
    //   }, 50);
    // }, 10);

    setTimeout(() => {
      ScrollTrigger.refresh(true);
      this.#zoneTwoAnimation();
      document.documentElement.scrollTo({ top: document.documentElement.scrollTop });
      this.timeArray.push(
        setTimeout(() => {
          document.documentElement.scrollTo({ top: document.documentElement.scrollTop });
        }, 100)
      );
      this.timeArray.push(
        setTimeout(() => {
          document.documentElement.scrollTo({ top: document.documentElement.scrollTop });
        }, 200)
      );
      this.timeArray.push(
        setTimeout(() => {
          document.documentElement.scrollTo({ top: document.documentElement.scrollTop });
        }, 300)
      );
    }, 100);
    this.#applyVideoBubbleAnimation();
  }

  #killGsapObj(gsapInfos) {
    if (!gsapInfos || gsapInfos.length === 0) return;
    gsapInfos.forEach(({ gsapObj }) => gsapObj.kill());
  }

  #createVideoBubbleAnimationInfo() {
    this.#killGsapObj(this.videoBubbleGsaps);

    this.videoBubbleGsaps = this.$videoBubbles.reduce((acc, bubbleEl) => {
      return [...acc, { target: bubbleEl, gsapObj: gsap.timeline({ repeat: -1 }) }];
    }, []);
  }

  #createFaceIconAnimationInfo() {
    this.#killGsapObj(this.faceIconGsaps);

    this.faceIconGsaps = this.$faceIcons.reduce((acc, faceIconEl) => {
      return [...acc, { target: faceIconEl, gsapObj: gsap.timeline({ delay: gsap.utils.random(0, 2) }) }];
    }, []);
  }

  #createLogoCharAnimationInfo() {
    let randomizedLogoCharacters = [...this.$logoCharacters].sort(() => Math.random() - 0.5);

    this.#killGsapObj(this.logoCharacterGsaps);

    const logoGsap = gsap.timeline({
      onRepeat: () => {
        randomizedLogoCharacters = randomizedLogoCharacters.sort(() => Math.random() - 0.5);
      },
      repeat: -1,
      repeatDelay: 1.9,
    });

    this.logoCharacterGsaps = randomizedLogoCharacters.reduce((acc, logoCharEl) => {
      return [...acc, { target: logoCharEl, gsapObj: logoGsap }];
    }, []);
  }

  #createZoneFiveBackgroundAnimationInfo() {
    this.#killGsapObj(this.zoneFiveBackgroundGsaps);

    this.zoneFiveBackgroundGsaps = [
      { trigger: this.$zoneFiveWrapper, start: 'top-=200 top', end: 'top+=400', scrub: true },
      { trigger: this.$zoneFiveWrapper, start: 'top+=200 top', end: 'top+=600', scrub: true },
      { trigger: this.$zoneFiveWrapper, start: 'top+=400 top', end: 'top+=900', scrub: true },
    ].reduce((acc, { trigger, start, end, scrub }, idx) => {
      return [
        ...acc,
        {
          target: this.$zoneFiveObjects[idx].firstChild,
          gsapObj: gsap.timeline({
            scrollTrigger: {
              trigger,
              start,
              end,
              scrub,
            },
          }),
        },
      ];
    }, []);
  }

  #loadHeaderSvgIcon() {
    fetch(`${process.env.CDN_DOMAIN}/logo-v3.svg`)
      .then((response) => {
        return response.text();
      })
      .then((svgData) => {
        this.#changeImgIconToSvg(this.$header, svgData);
      });
  }

  #changeImgIconToSvg(targetDom, svgData) {
    const $svgIconWrapper = document.createElement('div');
    $svgIconWrapper.classList.add('svg-icon');
    $svgIconWrapper.innerHTML = svgData.replaceAll(/fill="#4848FF"/g, '');

    targetDom.innerHTML = '';
    targetDom.append($svgIconWrapper);
  }

  /**
   * Zone01의 비디오 버블 애니메이션
   */
  #applyVideoBubbleAnimation() {
    const bubbleStartY = this.$zoneOneWrapper.offsetHeight;
    this.#createVideoBubbleAnimationInfo();

    [
      [
        { fn: 'to', args: [{ y: bubbleStartY, duration: 0 }] },
        { fn: 'to', args: [{ y: '-200%', duration: 8, ease: 'none' }] },
        { fn: 'to', args: [{ delay: 5 }] },
      ],
      [
        { fn: 'delay', args: [4] },
        { fn: 'to', args: [{ y: bubbleStartY, duration: 0 }] },
        { fn: 'to', args: [{ y: '-200%', duration: 8, ease: 'none' }] },
        { fn: 'to', args: [{ delay: 5 }] },
      ],
      [
        { fn: 'delay', args: [1.5] },
        { fn: 'to', args: [{ y: bubbleStartY, duration: 0 }] },
        { fn: 'to', args: [{ y: '-200%', duration: 8, ease: 'none' }] },
        { fn: 'to', args: [{ delay: 5 }] },
      ],
      [
        { fn: 'delay', args: [8.5] },
        { fn: 'to', args: [{ y: bubbleStartY, duration: 0 }] },
        { fn: 'to', args: [{ y: '-200%', duration: 8, ease: 'none' }] },
        { fn: 'to', args: [{ delay: 5 }] },
      ],
      [
        { fn: 'delay', args: [6.5] },
        { fn: 'to', args: [{ y: bubbleStartY, duration: 0 }] },
        { fn: 'to', args: [{ y: '-200%', duration: 8, ease: 'none' }] },
        { fn: 'to', args: [{ delay: 5 }] },
      ],
    ].forEach((sequence, idx) => this.#pipeLine(this.videoBubbleGsaps[idx], sequence));
  }

  /**
   * Zone01의 로고 애니메이션
   */
  #applyLogoAnimation() {
    this.#createLogoCharAnimationInfo();

    this.#logoAnimationStep1();
    this.#logAnimationStep2();
  }

  /**
   * Zone01의 로고 애니메이션 step1
   * applyLogoAnimation에서 호출
   */
  #logoAnimationStep1() {
    this.logoCharacterGsaps.forEach(({ gsapObj }) => gsapObj.addLabel('scale'));

    [
      [
        { fn: 'to', args: [{ scale: 1.5, duration: 0.1 }, 'scale'] },
        { fn: 'to', args: [{ scale: 1, duration: 0.1, delay: 0.1 }, 'scale'] },
        { fn: 'to', args: [{ scale: 1.05, duration: 0.1, delay: 0.2 }, 'scale'] },
        { fn: 'to', args: [{ scale: 1, duration: 0.1, delay: 0.3 }, 'scale'] },
      ],
      [
        { fn: 'to', args: [{ scale: 1.5, duration: 0.1, delay: 0.1 }, 'scale'] },
        { fn: 'to', args: [{ scale: 1, duration: 0.1, delay: 0.2 }, 'scale'] },
        { fn: 'to', args: [{ scale: 1.05, duration: 0.1, delay: 0.3 }, 'scale'] },
        { fn: 'to', args: [{ scale: 1, duration: 0.1, delay: 0.4 }, 'scale'] },
      ],
      [
        { fn: 'to', args: [{ scale: 1.5, duration: 0.1, delay: 0.2 }, 'scale'] },
        { fn: 'to', args: [{ scale: 1, duration: 0.1, delay: 0.3 }, 'scale'] },
        { fn: 'to', args: [{ scale: 1.05, duration: 0.1, delay: 0.4 }, 'scale'] },
        { fn: 'to', args: [{ scale: 1, duration: 0.1, delay: 0.5 }, 'scale'] },
      ],
      [
        { fn: 'to', args: [{ scale: 1.5, duration: 0.1, delay: 0.3 }, 'scale'] },
        { fn: 'to', args: [{ scale: 1, duration: 0.1, delay: 0.4 }, 'scale'] },
        { fn: 'to', args: [{ scale: 1.05, duration: 0.1, delay: 0.5 }, 'scale'] },
        { fn: 'to', args: [{ scale: 1, duration: 0.1, delay: 0.6 }, 'scale'] },
      ],
      [
        { fn: 'to', args: [{ scale: 1.5, duration: 0.1, delay: 0.4 }, 'scale'] },
        { fn: 'to', args: [{ scale: 1, duration: 0.1, delay: 0.5 }, 'scale'] },
        { fn: 'to', args: [{ scale: 1.05, duration: 0.1, delay: 0.6 }, 'scale'] },
        { fn: 'to', args: [{ scale: 1, duration: 0.1, delay: 0.7 }, 'scale'] },
      ],
      [
        { fn: 'to', args: [{ scale: 1.5, duration: 0.1, delay: 0.5 }, 'scale'] },
        { fn: 'to', args: [{ scale: 1, duration: 0.1, delay: 0.6 }, 'scale'] },
        { fn: 'to', args: [{ scale: 1.05, duration: 0.1, delay: 0.7 }, 'scale'] },
        { fn: 'to', args: [{ scale: 1, duration: 0.1, delay: 0.8 }, 'scale'] },
      ],
    ].forEach((sequence, idx) => this.#pipeLine(this.logoCharacterGsaps[idx], sequence));
  }

  /**
   * Zone01의 로고 애니메이션 step2
   * applyLogoAnimation에서 호출
   */
  #logAnimationStep2() {
    this.logoCharacterGsaps.forEach(({ gsapObj }) => gsapObj.addLabel('wave', '+=1.9'));

    [
      [
        { fn: 'to', args: [{ y: -30, duration: 0.1 }, 'wave'] },
        { fn: 'to', args: [{ y: 0, duration: 0.1, delay: 0.1 }, 'wave'] },
        { fn: 'to', args: [{ y: 5, duration: 0.1, delay: 0.2 }, 'wave'] },
        { fn: 'to', args: [{ y: 0, duration: 0.1, delay: 0.3 }, 'wave'] },
      ],
      [
        { fn: 'to', args: [{ y: -30, duration: 0.1, delay: 0.1 }, 'wave'] },
        { fn: 'to', args: [{ y: 0, duration: 0.1, delay: 0.2 }, 'wave'] },
        { fn: 'to', args: [{ y: 5, duration: 0.1, delay: 0.3 }, 'wave'] },
        { fn: 'to', args: [{ y: 0, duration: 0.1, delay: 0.4 }, 'wave'] },
      ],
      [
        { fn: 'to', args: [{ y: -30, duration: 0.1, delay: 0.2 }, 'wave'] },
        { fn: 'to', args: [{ y: 0, duration: 0.1, delay: 0.3 }, 'wave'] },
        { fn: 'to', args: [{ y: 5, duration: 0.1, delay: 0.4 }, 'wave'] },
        { fn: 'to', args: [{ y: 0, duration: 0.1, delay: 0.5 }, 'wave'] },
      ],
      [
        { fn: 'to', args: [{ y: -30, duration: 0.1, delay: 0.3 }, 'wave'] },
        { fn: 'to', args: [{ y: 0, duration: 0.1, delay: 0.4 }, 'wave'] },
        { fn: 'to', args: [{ y: 5, duration: 0.1, delay: 0.5 }, 'wave'] },
        { fn: 'to', args: [{ y: 0, duration: 0.1, delay: 0.6 }, 'wave'] },
      ],
      [
        { fn: 'to', args: [{ y: -30, duration: 0.1, delay: 0.4 }, 'wave'] },
        { fn: 'to', args: [{ y: 0, duration: 0.1, delay: 0.5 }, 'wave'] },
        { fn: 'to', args: [{ y: 5, duration: 0.1, delay: 0.6 }, 'wave'] },
        { fn: 'to', args: [{ y: 0, duration: 0.1, delay: 0.7 }, 'wave'] },
      ],
      [
        { fn: 'to', args: [{ y: -30, duration: 0.1, delay: 0.5 }, 'wave'] },
        { fn: 'to', args: [{ y: 0, duration: 0.1, delay: 0.6 }, 'wave'] },
        { fn: 'to', args: [{ y: 5, duration: 0.1, delay: 0.7 }, 'wave'] },
        { fn: 'to', args: [{ y: 0, duration: 0.1, delay: 0.8 }, 'wave'] },
      ],
    ].forEach((sequence, idx) => this.#pipeLine(this.logoCharacterGsaps[idx], sequence));
  }

  /**
   * Zone01의 얼굴 아이콘 애니메이션
   */
  #applyFaceIconAnimation() {
    this.#createFaceIconAnimationInfo();

    Array.from({ length: 3 }, () => [
      { fn: 'from', args: [{ opacity: 0, scale: 0.8, duration: 0.1 }] },
      { fn: 'to', args: [{ opacity: 1, scale: 1.1, duration: 0.1 }] },
      { fn: 'to', args: [{ opacity: 1, scale: 1.1, duration: 0.1 }] },
    ]).forEach((sequence, idx) => this.#pipeLine(this.faceIconGsaps[idx], sequence));
  }

  /**
   * Zone01 애니메이션
   */
  #zoneOneAnimation() {
    this.#applyVideoBubbleAnimation();
    this.#applyLogoAnimation();
    this.#applyFaceIconAnimation();
  }

  /**
   * zone02 애니메이션
   */
  #zoneTwoAnimation() {
    const zoneTwoHalfHeight = Math.floor(this.$zoneTwoWrapper.offsetHeight / 2);

    if (this.headerScrollTrigger) this.headerScrollTrigger.kill();

    this.headerScrollTrigger = ScrollTrigger.create({
      trigger: this.$zoneTwoWrapper,
      start: `top-=${GNB_HEIGHT} top`,
      end: `bottom+=${GNB_HEIGHT}`,
      pin: true,
      onEnter: () => {
        this.$headerWrapper.classList.remove('intro-black');
        this.$headerWrapper.classList.add('intro-white');
      },
      onLeaveBack: () => {
        this.$headerWrapper.classList.add('intro-black');
        this.$headerWrapper.classList.remove('intro-white');
      },
    });

    if (this.zoneTwoScrollTrigger) this.zoneTwoScrollTrigger.kill();

    this.zoneTwoScrollTrigger = ScrollTrigger.create({
      trigger: this.$zoneTwoWrapper,
      start: `top-=${GNB_HEIGHT}`,
      end: `top+=${zoneTwoHalfHeight - GNB_HEIGHT}`,
      onEnter: () => {
        this.$zoneTwoWrapper.classList.remove('step2');
        this.$zoneTwoWrapper.classList.add('step1');
      },
      onEnterBack: () => {
        this.$zoneTwoWrapper.classList.remove('step2');
        this.$zoneTwoWrapper.classList.add('step1');
      },
      onLeave: () => {
        this.$zoneTwoWrapper.classList.add('step2');
        this.$zoneTwoWrapper.classList.remove('step1');
      },
      onLeaveBack: () => {
        this.$zoneTwoWrapper.classList.add('step1');
        this.$zoneTwoWrapper.classList.remove('step2');
      },
    });
  }

  /**
   * zone04 애니메이션
   */
  #zoneFourAnimation() {
    ScrollTrigger.create({
      trigger: this.$zoneFourWrapper,
      start: 'top-=100 top',
      end: 'bottom',
      onEnter: () => {
        this.$zoneFourWrapper.classList.add('active');
      },
    });
  }

  /**
   * zone05 애니메이션
   */
  #zoneFiveAnimation() {
    [
      { trigger: this.$zoneFiveWrapper, start: 'top top', end: 'top+=400', once: true },
      { trigger: this.$zoneFiveWrapper, start: 'top+=300 top', end: 'top+=500', once: true },
      { trigger: this.$zoneFiveWrapper, start: 'top+=700 top', end: 'top+=900', once: true },
    ].forEach(({ trigger, start, end, once }, idx) => {
      ScrollTrigger.create({
        trigger,
        start,
        end,
        once,
        onEnter: () => {
          this.$zoneFiveObjects[idx].classList.add('pop');
        },
      });
    });

    this.#createZoneFiveBackgroundAnimationInfo();

    Array.from({ length: 3 }, () => [
      { fn: 'from', args: [{ marginTop: '-5%' }] },
      { fn: 'to', args: [{ marginTop: 0 }] },
    ]).forEach((sequence, idx) => this.#pipeLine(this.zoneFiveBackgroundGsaps[idx], sequence));
  }

  #pipeLine(gsapInfo, sequence) {
    const { gsapObj, target } = gsapInfo;
    sequence.forEach(({ fn, args }) => {
      if (fn === 'delay') {
        gsapObj[fn](...args);
      } else {
        gsapObj[fn](target, ...args);
      }
    });
  }
}
