
export default {
  name: 'ZoneTwo',

  emits: ['add-observer-el'],
  data: () => {
    return {};
  },
  computed: {
    images() {
      const locale = this.$i18n.locale;
      return {
        one1: `/images/locales/${locale}/intro/zone02-text-1_1.svg`,
        one2: `/images/locales/${locale}/intro/zone02-text-1_2.svg`,
        two1: `/images/locales/${locale}/intro/zone02-text-2_1.svg`,
        two2: `/images/locales/${locale}/intro/zone02-text-2_2.svg`,
        three1: `/images/locales/${locale}/intro/zone02-text-3_1.svg`,
        three2: `/images/locales/${locale}/intro/zone02-text-3_2.svg`,
        four1: `/images/locales/${locale}/intro/zone02-text-4_1.svg`,
        four2: `/images/locales/${locale}/intro/zone02-text-4_2.svg`,
      };
    },
  },
};
