
export default {
  name: 'ZoneFour',

  emits: ['add-observer-el'],

  computed: {
    images() {
      const locale = this.$i18n.locale;
      return {
        one: `/images/locales/${locale}/intro/zone04-bubble-1.png`,
        one2: `/images/locales/${locale}/intro/zone04-bubble-1@2x.png 2x`,
        two: `/images/locales/${locale}/intro/zone04-bubble-2.png`,
        two2: `/images/locales/${locale}/intro/zone04-bubble-2@2x.png 2x`,
      };
    },
  },
};
