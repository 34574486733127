import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=6ce1e5e2"
import script from "./Footer.vue?vue&type=script&lang=js"
export * from "./Footer.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SelectLanguage: require('/stove/deploy/stove-media/src/components/mobile/Common/SelectLanguage.vue').default})
